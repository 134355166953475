.form-card-block {
  border-radius: 5px;
  backdrop-filter: blur(67px);
  max-width: 1040px;
  height: auto;
  margin: 0 auto;
  margin-top: 160px;
  text-align: center;
  padding: 50px;
}

.form-card-info {
  position: relative;
}

.input-form {
  /* border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; */
  /* opacity: 0.10000000149011612; */
  border: none;
  background: #f6f6f6;
  width: 400px;
  height: 55px;
  padding-left: 10px;
}
.input-form:focus {
  outline: none;
  border-color: #ccc; /* Пример: меняем цвет рамки элемента при фокусе */
  box-shadow: none;
}

@media all and (max-width: 600px) {
  .input-form {
    width: 100%;
  }
}
