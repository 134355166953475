.invest {
  color: #99efd0;
  font-size: 46px;
}

.invest-text {
  color: white;
}

.button-invest {
  width: 160px;
  height: 50px;
  box-shadow: -11.09000015258789px 18.1299991607666px 36.648292541503906px 0px
    #f2994a3b;
}

.invest-card-block {
  position: relative;
}

.invest-card {
  background: white;
  width: 260px;
  height: 250px;
  border-radius: 10px;
}

.invest-block {
  display: flex;
  justify-content: space-evenly;
  margin-top: 120px;
  align-items: center;
  position: relative;
}

.rectangle-img {
  margin-bottom: -20px;
}

.rectangle2-img {
  position: absolute;
  left: -70px;
  top: 110px;
}

.MaskGroup-img {
  position: absolute;
  z-index: -1;
  right: 10px;
  width: 42%;
}

.invest-card-info {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 35px;
}

.invest-mini-text {
  color: #637381;
  font-size: 10px;
}

.invest-mini-block {
  display: flex;
  justify-content: space-between;
}

.invest-mini-card {
  border-radius: 10px;
  background: #516ea9;
  box-shadow: -8px 16px 60px 0px rgba(82, 114, 139, 0.24);
  width: 130px;
  height: 65px;
  z-index: -1;
  position: absolute;
  left: 255px;
  color: white;
  bottom: 115px;
}

.mini-card-text {
  padding-left: 20px;
}

@media all and (max-width: 1100px) {
  .invest-block {
    flex-direction: column;
    text-align: center;
  }
  .invest-card-block {
    margin-top: 50px;
  }
}

@media all and (max-width: 530px) {
  .invest-mini-card {
    left: 60px;
    bottom: -30px;
    z-index: 1;
  }
  .mini-card-text {
    padding-left: 0px;
  }
}

@media all and (max-width: 410px) {
  .invest {
    font-size: 38px;
  }
}
