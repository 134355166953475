.main_header {
  padding: 10px 0 10px;
  /* border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; */
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  transition: 0.7s;
}
.main_header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(72deg, #40896e 0%, #000000 100%);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.7s;
}

.main_header.scroll-top-active::before {
  opacity: 1;
  transition: 0.7s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo_nav {
  /* max-width: 490px; */
  display: flex;
  justify-content: space-between;
}
.logo-box img {
  max-width: 80px;
  cursor: pointer;
}
.nav {
  display: flex;
  margin-left: 30px;
  justify-content: space-between;
}
.nav p {
  font-family: "Gilroy", sans-serif;
  margin-right: 30px;
  color: #b5b5b5;
  transition: 0.5s;
  width: 90px;
  text-align: center;
}
.nav p:hover {
  color: #efefef;
  cursor: pointer;
}
.burger_menu img {
  width: 40px;
  cursor: pointer;
}

.scroll-top-active {
  background: linear-gradient(72deg, #40896e7b 0%, #000000 100%);
  transition: 1s;
}
.scroll-top {
  transition: 1s;
  padding-top: 20px;
}

.burger_menu {
  display: flex;
  align-items: center;
  color: white;
}
@media all and (max-width: 800px) {
  .nav {
    display: none;
  }
  .nav_btns {
    display: none;
  }
  .main_header {
    background: linear-gradient(180deg, #99efcf5c 0%, #00000000 100%);
  }
}

/* @media all and (min-width: 800px) {
  .burger_menu {
    display: none;
  }
} */

@media all and (max-width: 800px) {
  .main_header::before {
    background-image: linear-gradient(0deg, #00ffa314 0%, #99efd0 100%);
  }
}

