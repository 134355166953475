:root {
  --main-color: #99efd0;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
}
body {
  /* background: linear-gradient(225deg, #3b1d60 0%, #040d3e 100%); */
  background: black;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
}
.runner .runner_inner {
  transition: 0.6s;
}
.runner:hover .runner_inner {
  stop-color: #2deda7 !important;
}

.runner {
  transform: translate3d(0, -100%, 0);
  animation: spin 5s infinite linear;
}

@keyframes spin {
  0% {
    transform: translate3d(0, -3%, 0);
  }
  50% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -3%, 0);
  }
}
