.project {
  color: #99efd0;
  font-family: "inter ", sans-serif;
}

.button-project {
  width: 160px;
  height: 50px;
  box-shadow: -11.09000015258789px 18.1299991607666px 36.648292541503906px 0px
    #f2994a3b;
}

.project-block {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}
.project-block p {
  max-width: 620px;
}

.frame-img {
  position: absolute;
  max-width: 835px;
  top: 0;
}

@media all and (max-width: 700px) {
}
